<template>
    <div>
        <div class="mainContainer">
            <div class="hostpitalDoctorButton">
            <v-btn width="50%" :color="selectedTab == 'hospital' ? '#48ACEF' : '#e0e0e0'"  
            :outlined="selectedTab == 'hospital' ? false : true"
            :style="selectedTab =='hospital' ? 'color: white; border-radius: 8px;': 'border-radius: 8px;'" depressed class="text-none " 
            @click="() => {tabClicked('hospital')}">
                <div class="buttonTextContainer">
                    <img :src="selectedTab == 'hospital' ? 'https://s3iconimages.mymedicine.com.mm/hospitalIcon.svg' : 'https://s3iconimages.mymedicine.com.mm/hospitalGreyIcon.svg'" alt="">
                    <div style="font-weight: 600;">{{$t("Customer.OpdAppointments.Hospital")}}</div>
                </div>
            </v-btn>
            <v-btn width="50%" depressed :color="selectedTab == 'doctor' ? '#48ACEF' : '#e0e0e0'" 
            :outlined="selectedTab == 'doctor' ? false : true"
            :style="selectedTab =='doctor' ? 'color: white; border-radius: 8px;': 'border-radius: 8px;'"
            class="text-none" @click="() => {tabClicked('doctor')}">
                <div class="buttonTextContainer">
                    <img :src="selectedTab == 'doctor' ? 'https://s3iconimages.mymedicine.com.mm/doctorWhiteIcon.svg' : 'https://s3iconimages.mymedicine.com.mm/doctorGrayIcon.svg'" alt="">
                    <div style="font-weight: 600;">{{$t("Customer.OpdAppointments.Doctor")}}</div>
                </div>
            </v-btn>
        </div>  
        <div class="headerHeadingSearch" v-if="selectedTab == 'hospital'">
            <div class="stateName">
                <div>{{$t("Customer.OpdAppointments.Select_Region")}}</div>
                <div class="stateList">{{ selectedStateList }}</div>
            </div>
            <img src="https://s3iconimages.mymedicine.com.mm/DropdownIcon.svg" @click="openRegionSelector"/>
        </div>
        <div class="headerHeadingSearch" v-else style="width: 100%; padding: 0;">
            <OPDPackageSelectSpecialty :tabName="'OPDDOCTORFLOW'" />
        </div>

        <div class="searchHospital" id="searchBox">
            <div class="input-wrapper">
                <img src="https://s3iconimages.mymedicine.com.mm/searchIcon.svg" alt="Icon" class="input-icon">
                <input type="text" class="input-box" :placeholder="$t('Customer.OpdAppointments.Search')" v-model="searchQuery" @input="searchFilter" style="color: #4f4f4f;">
            </div>
        </div>
        </div>
        <v-bottom-sheet  :persistent="true" v-model="searchSheet" max-width="425px">
            <v-sheet
            class="text-center"
            height="600px"
            >
            <div class="searchStateBottomContainer">
            <div>
                <div class="selectRegionBottomSheetHeading">
                    <div>{{$t("Customer.OpdAppointments.Select_Region")}}</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="closeBottomSheet" style="cursor: pointer;"/>
                </div>
                <div class="">
                    <div class="input-wrapper" style="background-color: #F4F6FA; border: none; padding: 12px 16px 12px 16px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/searchIcon.svg" alt="Icon" class="input-icon">
                        <input type="text" class="input-box input-box-spec" :placeholder="$t('Customer.OpdAppointments.Search')" v-model="searchQueryRegion" @input="searchFilterRegion" style="color: #4f4f4f;">
                    </div>
                </div>
            </div>    
            <div class="stateChipsContainer">
                <span v-for="(item) in stateList" :key="item.id">
                    <v-chip  :ripple="false" v-if="temporarySelectedStateList.includes(item.id) == true" color="#48ACEF29" @click="removeMeFromTheList(item.id)" style="color: #1467BF;"><span class="mdi mdi-check blue-tick"></span>{{ item.name }}</v-chip>
                    <v-chip :ripple="false" v-else outlined @click="temporarySelectedStateList.push(item.id)">{{ item.name }}</v-chip>
                </span>        
            </div>
            <div class="stateBottomSheetBottomBar">
                <div style="color: #1467BF; font-weight: 500; cursor: pointer;" @click="clearRegionFilter">{{$t("Customer.OpdAppointments.Clear_All")}}</div>
                <v-btn color="#48acef" depressed style="color: white;" @click="applyRegionFilters">{{$t("Customer.OpdAppointments.Apply")}}</v-btn>
            </div>
            </div>
        </v-sheet>
        </v-bottom-sheet>
        <v-bottom-sheet  :persistent="true" v-model="specialitySheet" max-width="425px">
            <v-sheet
            class="text-center"
            height="370px"
            >
            <div class="searchStateBottomContainer">
            <div>
                <div class="selectRegionBottomSheetHeading">
                    <div>{{$t("Customer.OpdAppointments.Select_Speciality")}}</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg"  @click="specialitySheet = false" style="cursor: pointer;"/>
                </div>
                <div class="" >
                    <div class="input-wrapper" style="background-color: #F4F6FA; border: none; padding: 12px 16px 12px 16px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/searchIcon.svg" alt="Icon" class="input-icon">
                        <input type="text" class="input-box input-box-spec" :placeholder="$t('Customer.OpdAppointments.Search')" v-model="searchQueryRegion" @input="searchFilterRegion" style="color: #4f4f4f;">
                    </div>
                </div>
            </div>    
            <div class="stateChipsContainer">
                <div class="specialtyBox" v-for="(item) in specialtyList" :key="item.id">
                    <div>{{ item.name }}</div>
                </div>
            </div>
        </div>
        </v-sheet>
        </v-bottom-sheet>
    </div>
    
</template>

<script>
import OPDPackageSelectSpecialty from '../../../OPDPackagePages/componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageSelectSpecialty.vue';



export default {
  components: {OPDPackageSelectSpecialty },
    name: 'SelectRegionAndSeachComp',
    // props: ['switchTab'],
    data: function() {
        return {
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            searchSheet: false,
            specialitySheet: false,
            // stateList: ['Yangoon', 'Akjhdyux', 'kjldsffds yunsdf', 'dsfsdafsdf dsfds', 'sdafsdf sdfsdf', 'Akjhdyux', 'kjldsffds yunsdf', 'kjldsffds yunsdf', 'dsfsdafsdf dsfds', 'sdafsdf sdfsdf', 'Akjhdyux', 'kjldsffds yunsdf', 'dsfsdafsdf dsfds', 'sdafsdf sdfsdf'],
            searchQuery: '',
            temporarySelectedStateList: [],
            searchQueryRegion: '',
            selectedTab: 'hospital',
        };
    },
    computed: {
        stateList () {
            return this.$store.state.locationState.showLocationList;
        },
        selectedStateList () {
            let filterRegions = this.$store.state.opdHospitalState.filterRegions;
            let ans = '';
            for(let i = 0; i<filterRegions.length; i++) {
                if (i == 1 ) {
                    ans += ' +' + String(filterRegions.length - i);
                    break;
                } else {
                    if (i != 0) ans += ', ';
                    this.stateList.map((item) => {
                        if (item.id == filterRegions[i]) {
                            ans += item.name;
                        }
                    })
                }
            }
            return ans;
        },
        specialtyList () {
            return this.$store.state.OPDConfigCommonState.opdSpec;        
        },
    },
    async mounted() {
    },

    methods: {
        tabClicked(value) {
            this.searchQuery = '';
            this.selectedTab = value;
            this.$emit('switchTab', value);
        }, 
        removeMeFromTheList(id) {
            this.temporarySelectedStateList = this.temporarySelectedStateList.filter(function(item) {
        })
        },
        applyRegionFilters() {
            this.$store.dispatch('applyRegionFilter', {regions: this.temporarySelectedStateList});
            this.closeBottomSheet();
        },
        clearRegionFilter() {
            this.temporarySelectedStateList = [];
            this.$store.dispatch('applyRegionFilter', {regions: []});
            this.closeBottomSheet();
            
        },
        searchFilter() {
            if (this.searchQuery.length >= 3) {
                if (this.selectedTab == 'hospital')
                    this.$store.dispatch('searchHospital', { searchQuery: this.searchQuery});
                else {
                    this.$store.dispatch('searchDoctorDoctorFlow', { searchQuery: this.searchQuery});
                }
            } else if (this.searchQuery.length == 0) {
                if (this.selectedTab == 'hospital')
                    this.$store.dispatch('removeSearchQuery', { searchQuery: this.searchQuery});
                else {
                    this.$store.dispatch('removeSearchQueryDoctor', { searchQuery: this.searchQuery});
                }

            }
        },
        searchFilterRegion() {
            if (this.searchQueryRegion.length >= 3) {
                this.$store.dispatch('searchLocation', { searchQuery: this.searchQueryRegion});
            } else if (this.searchQueryRegion.length == 0) {
                this.$store.dispatch('removeLocationSearchQuery');
            }
        },
        closeBottomSheet() {
            this.searchSheet = false;
        },
        openRegionSelector() {
            this.searchSheet = true;
            this.temporarySelectedStateList = this.$store.state.opdHospitalState.filterRegions;
        },
        async loadHomePageData() {

        },
    },
};

</script>
<style  scoped>
.input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #e8e8eb;
    border-radius: 6px;
    padding: 5px 10px;
    background-color: #f9f9f9;
}

.input-icon {
    height: 20px; /* Adjust based on your icon's size */
    margin-right: 10px;
}
.specialtyBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-bottom: 0.1px solid #e8e8eb;
    cursor: pointer;
}
.input-box {
    border: none;
    outline: none;
    background-color: transparent;
    flex-grow: 1;
}
.buttonTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.blue-tick {
  font-weight: bold !important;
  color: #1467BF; 
  padding-right: 4px;
  /* You can specify any shade of blue you prefer */
}
.mainContainer {
    width: 100%;
    background-color: white;
    
}
.headerHeadingSearch {
    width: 100%;
    color: Black;
    font-weight: 500;
    padding: 12px 20px 12px 20px;
    border-bottom: 1px solid #e8e8eb;
    display: flex;
    justify-content: space-between;
    cursor: pointer;


}
.hostpitalDoctorButton {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 12px 16px 12px 16px;
    gap: 12px;
    border-bottom: 1px solid #e8e8eb;

}
.searchHospital {
    width: 100%;
    border-bottom: 1px solid #e8e8eb;
    padding: 12px 16px 12px 16px;


}
.v-input >>> .v-text-field__details {
    display: none !important;
}
.stateName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.stateList {
    font-weight: 400;
    font-size: 14px;
    color: #4f4f4f;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.selectRegionBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.stateChipsContainer {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex-grow: 1;
    overflow-y: scroll;
    max-height: 430px;
    border-top: 1px solid #e8e8eb;

}
.stateBottomSheetBottomBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px 12px 16px;
    border-top: 1px solid #e8e8eb;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 425px;
}
.searchStateBottomContainer {
    display: flex;
    flex-direction: column;
}
.v-dialog__content >>> .v-bottom-sheet {
    box-shadow: none !important;
}

.input-box::placeholder {
    color: #35383a; /* Placeholder text color */
    font-weight: 400;
    font-size: 14px;
}

</style>