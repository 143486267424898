<template>
<div class="mainContainerDoctorInfo">
    <!-- <div style="font-size: 14px; font-weight: 600; margin-bottom: 10px;">Appointment Details</div> -->
    <div style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start; gap: 20px; border: 1px solid #e0e0e0; border-radius: 8px; padding: 16px;">
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
            <div class="doctorNameAndLogo">
                <div class="doctorProfile">
                    <img :src="bookingInfo.doctorInfo.doctorLogoURL" style="border-radius: 50%;"/>
                </div>
                <div class="doctorName">
                    <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.doctorInfo.doctorName }}</div>
                    <div style="color: #1467BF; font-weight: 400; font-size: 14px;" class="specialtyBox" v-if="specialtyList">{{ getSpecialtyName(bookingInfo.doctorInfo.doctorSpecialty) ? getSpecialtyName(bookingInfo.doctorInfo.doctorSpecialty): bookingInfo.doctorInfo.doctorSpecialty  }}</div>
                </div>
            </div>
            <div>
                <v-btn 
                    outlined
                    color="#e8e8eb"
                    @click="viewDoctorClicked"
                >
                    <div style="color: #1467BF; font-weight: 400; font-size: 14px;">{{$t("Customer.OpdAppointments.View")}}</div>
                </v-btn>
            </div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 5px;">
            <div style="font-size: 14px; color: #6f717e;">{{$t("Customer.PackageAppointments.slot_date_time")}}:</div>
            <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.doctorInfo.slotDate }},  {{ bookingInfo.doctorInfo.slotTime }}</div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 10px;">
            <div style="font-size: 14px; color: #6f717e;">{{$t("Customer.CartPage.Address")}}:</div>
            <div class="hospitalNameAndLogo">
                <div class="hospitalLogo">
                    <img :src="bookingInfo.doctorInfo.hospitalLogo" style="border-radius: 8px; max-width: 100%; max-height: 100%; object-fit: contain;"/>
                </div>
                <div class="hospitalName">
                    <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.doctorInfo.hospitalName }}</div>
                    <div style="color: #4f4f4f; font-size: 12px; font-weight: 400;">{{ bookingInfo.doctorInfo.hospitalAddress }}</div>
                </div>
        </div>
        </div>
    </div>


</div>
</template>

<script>



export default {
    name: 'DoctorInfoComponent',
    props: ['bookingInfo'],
    data: function() {
        return {
            sheet: true,
            viewDoctor: true,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            viewDetailsModel: false,
            hospitalName: 'Asia Royal Hospital',
            hospitalLocation: 'Yangon',
            hospitalRegNumber: '1241424',
            phoneNumber: '+95 67980342453',
            reviews: '299',
            doctorCount: 56,
            fullDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint.',
            readMore: false,
        };
    },
    computed: {
        textPreview() {
            return this.fullDescription.slice(0, 100) + '...';
        },
        specialtyList () {
            return this.$store.state.OPDConfigCommonState.opdSpec;        
        },
    },
    async mounted() {
    },
    methods: {
        getSpecialtyName(item) {
            let findItem = this.specialtyList.find((x) => (x.id == item));
            console.log(findItem, item, this.specialtyList);
            if (findItem) {
                return findItem.name;
            } else {
                return ''
            }
        },
        viewDoctorClicked() {
            this.$emit('toggleViewDoctorDialog', true);
        },
        goToOPDPage() {
            this.$router.push({
                name: 'OPDHome'
            })
        },
        toggleHospitalDetails() {
            this.viewDetailsModel = !this.viewDetailsModel;
        },
        closeBottomSheet() {
            this.viewDetailsModel = false;
        },
        descriptionPreview() {

        }
    },
};

</script>
<style  scoped>
.mainContainerDoctorInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    /* margin-top: 50px; */
}
.doctorName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}
.doctorProfile {
    border-radius: 50%;
    max-height: 56px;
    max-width: 56px;
    height: 56px;
    width: 56px;
    border: 1px solid #e0e0e0;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.doctorNameAndLogo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
}
.specialtyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4F5FF;
    padding: 3px 8px 3px 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalNameAndLogo {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    font-size: 14px !important;
}
.bottomDoctorContainer {
    width: 100%;
    background: red;
    height: 60vh;
    position: absolute;
    bottom: 0;
    border-radius: 16px;
    max-width: 425px;
    border-top: 1px solid #e8e8eb;
    z-index: 100;
}
</style>
    