import { render, staticRenderFns } from "./hospitalHeaderComp.vue?vue&type=template&id=84b84b26&scoped=true&"
import script from "./hospitalHeaderComp.vue?vue&type=script&lang=js&"
export * from "./hospitalHeaderComp.vue?vue&type=script&lang=js&"
import style0 from "./hospitalHeaderComp.vue?vue&type=style&index=0&id=84b84b26&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84b84b26",
  null
  
)

export default component.exports