<template>
    <v-bottom-sheet  :persistent="true" v-model="modelValue" max-width="425px" persistent   >
        <v-sheet
            height="410px"
        >
        <div class="bottomSheetContainer">
            <div class="bottomSheetHeader">
                <div style="font-size: 14px; font-weight: 500; color: #4f4f4f;">{{$t("Customer.BookAppointment.Add_Dependent")}}</div>
                <img src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg" style="cursor: pointer;" @click="closeDialog"/>
            </div>
            <div style="height: 70vh; overflow: scroll;">
                <div style="padding: 16px;">
                    <div class="inputBox">
                        <span style="color: #828282;">{{$t("Customer.OpdAppointments.Name")}}*</span>
                        <v-text-field
                            :dense="true"
                            :label="$t('Customer.OpdAppointments.Name')"
                            v-model="name"
                            placeholder="Name"
                            outlined
                            color="SUCCESS"
                            full-width="true"
                        ></v-text-field>
                    </div>
                    <div class="inputBox">
                        <span style="color: #828282;">{{$t("Customer.OpdAppointments.DOB")}}*</span>
                        <v-menu ref="menu"  
                        
                      :close-on-content-click="false"
                        
                        v-model="startDateMenu" transition="scale-transition"  :offset-y="true" :offset-overflow="true" min-width="auto" >
                            <template v-slot:activator="{ on, attrs}">
                                <v-text-field :value="startDateFormatted" 
                                 :placeholder="$t('Customer.OpdAppointments.Date_of_birth')"
                                outlined
                                :dense="true" 
                                readonly  v-bind="attrs"  v-on="on"
                                @click:append="showStartDatePicker" 
                                style="border-radius:8px;" >
                                    <template v-slot:append>
                                        <img src="https://s3iconimages.mymedicine.com.mm/adminNurseCalenderBlackIcon.svg" />
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker v-model="startDateExport" :no-title="true" @change="menu=true" >
                            </v-date-picker>
                        </v-menu>
                    </div>                    
                    <div class="inputBox">
                        <span style="color: #828282;">{{$t("Customer.BookAppointment.Gender")}}</span>
                        <div style="display: flex; justify-content: space-evenly; gap: 10px;">
                            <div :class="getGenderClass(item)" v-for="item in genders" :key="item.id" @click="selectGender(item)">
                                <span>{{ item.value }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="inputBox">
                        <span style="color: #828282;">Phone Number</span>
                        <v-text-field
                            :dense="true"
                            label="Phone Number"
                            :placeholder="$t('Customer.OpdAppointments.phone_number')"
                            outlined
                            v-model="phoneNumber"
                            color="SUCCESS"
                            full-width="true"
                        ></v-text-field>
                    </div>
                    <div class="inputBox">
                        <span style="color: #828282;">{{$t("Customer.OpdAppointments.State_Township")}}</span>
                        <v-autocomplete
                            :items="stateList"
                            :placeholder="$t('Customer.OpdAppointments.select_State')"
                            label=""
                            :dense="true"
                            item-text="name"
                            item-value="id"
                            outlined
                            v-model="selectedState"
                        ></v-autocomplete>
                    </div>
                </div>
                </div>
            </div>
            <div class="bottomBar">
                <v-btn depressed color="#48acef" style="color: white;" width="100%" height="40px" @click="addDependent" :disabled="!isAddDependentAllowed || buttonLoading" :loading="buttonLoading">
                    <img src="https://s3iconimages.mymedicine.com.mm/whiteCircleTick.svg" alt="" style="width: 25px; height: 25px; margin-right: 10px;">
                    {{$t("Customer.BookAppointment.Add_Dependent")}}
                </v-btn>
            </div>        
        </div>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';



export default {
name: 'AddDependentComponent',
props: ['doctorInfo', 'modelValue'],
data: function() {
    return {
        x: true,
        selectedState: null,
        scrollableDiv: null,
        searchMeDiv: null,
        allDetailsDiv: null,
        selectedGenderID: 'MALE',
        buttonLoading: false,
        menu:false,
        genders: [
            {
                id: 'MALE',
                value: 'Male'
            },
            {
                id: 'FEMALE',
                value: 'Female'
            },{
                id: 'OTHERS',
                value: 'Others'
            },
        ],
        startDateMenu: false,
        startDateExport: null,
        name: '',
        phoneNumber: '',
        // bookingInfo: {},
    };
},
computed: {
    stateList () {
            return this.$store.state.locationState.showLocationList;
        },
    startDateFormatted() {
        this.startDateMenu = false;
        return this.startDateExport ? new Date(this.startDateExport).toLocaleDateString('en-GB') : '';
    },
    isAddDependentAllowed() {
        return !(!this.name || !this.selectedState || !this.startDateExport)
    }
},
async mounted() {
},

methods: {
    getGenderClass(item) {
        if (item.id == this.selectedGenderID) {
            return 'genderBoxSelected'
        } else {
            return 'genderBox'
        }
    },
    selectGender(item) {
        this.selectedGenderID = item.id;
    },
    closeDialog() {
        this.$emit('toggleAddDependentDialog', false);
    },
    async addDependent() {
        try {
            this.buttonLoading = true;
            let response = await axios_auth_instance_hba_customer.post('/user/adddependent', {
            name: this.name,
            gender: this.selectedGenderID,
            dob: this.startDateExport,
            state: String(this.selectedState) ,
            phoneNumber: this.phoneNumber
        });

        this.$emit('addDependentInList', {
            _id: response.data.dependentID,
            dependent_name: this.name,
            stateID: this.selectedState
        });
        } catch (error) {
            console.log(error);
        } finally {
            this.buttonLoading = false;
        }

    },
    showStartDatePicker() {
            alert('clicked')
            this.startDateMenu = !this.startDateMenu;
        },
    disableBackNavigation() {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = () => {
    this.$router.go(-1); // Prevent back navigation
  };
},
    goToPage(value) {
        this.$router.push({
            name: value,
        });
    },
},
};

</script>
<style  scoped>
.TokenContainer {
width: 150px;
height: 44px;
padding: 8px 8px 8px 16px;
border-radius: 32px;
display: flex;
justify-content: center;
align-items: center;
gap: 15px;
margin-top: 16px;
background: #1467BF1A;
}
.mobileContainer {
margin: auto;
width: 425px;
height: 100vh;
background-color: white;
/* max-height: 100vh; */
/* overflow: hidden; */
}
.bodyContainer {
width: 100%;
/* height: 100%; */
padding: 0 16px;
background-color: white;
/* background: red; */
}
.bottomBar {
position: absolute;
bottom: 0;
/* background: ; */
box-shadow: 0px -4px 8px 0px #0000000A;
box-shadow: 0px 4px 4px 0px #00000040;
width: 100%;
max-width: 425px;
display: flex;
align-items: center;
padding: 15px;
justify-content: space-evenly;
}
.bottomSheetContainer {
width: 100%;
position: absolute;
bottom: 0;
/* height: 70vh; */
background: #fff;
border: 1px solid #ebebe8;
border-radius: 16px;
max-width: 425px;
/* max-height: 70vh; */
/* overflow-y: scroll; */
/* z-index: 1000; */
}
.doctorBodyContainer {
padding: 32px 16px 32px 16px;
border-radius: 32px 32px 0 0;
/* border: 1px solid red; */
z-index: 1000;
background: #fff;
position: relative;
top: -32px;
}
.experienceBox {
padding: 8px 16px 8px 16px;
border: 1px solid #e0e0e0;
border-radius: 8px;
}
.bottomSheetHeader {
display: flex;
justify-content: space-between;
align-items: center;
padding: 12px 16px 12px 16px;
border-bottom: 1px solid #e8ebeb;
/* position: sticky; */
/* top: 0; */
}
.profileImage {
height: 300px;
/* min-height: 300px; */
max-width: 425px;
object-fit: cover;
width: 100%;
/* position: relative; */
/* bottom: -30px; */
z-index: -1;
}
.inputBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}
.genderBox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    border: 1px solid #e0e0e0;
}
.genderBoxSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    border: 1px solid #48acef;
    color: #48acef;
}
::v-deep .v-input{
    width: 100%;
}
::v-deep .v-text-field__details{
    display: none;
}
.bottomBar {
    border-top: 1px solid #e8e8eb;
    display: flex; justify-content: space-between; align-items: center; width: 100%;
    padding: 12px 16px 12px 16px;
    box-shadow: 0px -4px 8px 0px #0000000A;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: absolute;
    bottom: 0;
    max-width: 425px;
}
@media screen and (max-width: 425px){
.mobileContainer {
width: 100%;
}
}
::v-deep .v-date-picker-table .v-btn.v-btn--active {
    background-color: #1467BF !important;
    /* color: #fff !important; */
}
</style>